import React from 'react'
import { useNavigate } from 'react-router-dom'

import DdayBanner from '../components/DdayBanner'
import GoalOfDays from '../components/GoalOfDays'
import GroupRecommendedBooks from '../components/GroupRecommendedBooks'
import MyPageLayout from '../components/myPageLayout'
import StudyingReport from '../components/StudyingReport'
import { useBookcafeData, useMyPage, useNativeLayout } from '../module/myPage/hook'

export default function MyPageMain() {
  const navigate = useNavigate()
  const { user } = useBookcafeData()
  const { layout } = useNativeLayout()
  const { surveyParticipated } = useMyPage()

  return (
    <MyPageLayout user={user}>
      <span className='text-xs text-slate-700'>native-layout: {layout}</span>
      <button
        className='text-xs text-slate-700 border w-12'
        onClick={() => {
          navigate('/intro-survey')
        }}
      >
        intro-survey
      </button>
      <MyPageLayout.AccountNavigation user={user} />
      <MyPageLayout.Content surveyParticipated={surveyParticipated}>
        <DdayBanner />
        <GoalOfDays />
        <StudyingReport />
        <GroupRecommendedBooks />
      </MyPageLayout.Content>
    </MyPageLayout>
  )
}

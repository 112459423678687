import axios, { type AxiosError, type AxiosInstance } from 'axios'

import { type INativeInteractor } from '../module/nativeInteractor/nativeInteractor'

import {
  type Middleschool,
  type Highschool,
  type DataResponse,
  type University,
  type CertData,
  type UniversityMajor,
  type MiddleschoolClass,
  type HighschoolClass,
  type User,
  type UpdateResponse,
  type UserType,
  type SurveyResult,
  type SuggestBooks,
  type StatsOption,
  type StatsData,
  type LatestSurvey,
  type Dday,
} from './interfaces/sconnStudyData'

export default class SconnStudyDataClient {
  private readonly _httpClient: AxiosInstance
  private _expires: number | null = null
  #accessToken: string | null = null

  constructor(private readonly nativeInteractor: INativeInteractor) {
    this._httpClient = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
      headers: { 'Content-Type': 'application/json' },
    })

    this._httpClient.interceptors.request.use(
      (config) => {
        if (this.expires !== null) {
          if (this.expires - Date.now() / 1000 < 30) {
            this.nativeInteractor.getNativeToken().then((accessToken: string | undefined) => {
              if (accessToken) {
                this.setAccessToken(accessToken)
                this.expires = this.decodeExp(accessToken)
              } else {
                console.log('accessToken null from native')
              }
            })
          }
        }
        config.headers.Authorization = `Bearer ${this.#accessToken}`
        return config
      },
      async (error: AxiosError) => {
        console.error('Request Error Interceptor:', error)
        return await Promise.reject(error)
      },
    )

    this._httpClient.interceptors.response.use(
      (response) => {
        if (this.expires !== null) {
          if (this.expires - Date.now() / 1000 < 30) {
            this.nativeInteractor.getNativeToken().then((accessToken: string | undefined) => {
              if (accessToken) {
                this.setAccessToken(accessToken)
                this.expires = this.decodeExp(accessToken)
              } else {
                console.log('accessToken null from native')
              }
            })
          }
        }
        response.headers.Authorization = `Bearer ${this.#accessToken}`
        return response
      },
      async (error) => {
        console.error('Response Error Interceptor:', error)
        return await Promise.reject(error)
      },
    )
  }

  get expires(): number | null {
    return this._expires
  }

  set expires(exp: number) {
    this._expires = exp
  }

  async getUserTypes() {
    return await this._httpClient.get<DataResponse<UserType[]>>('/list/usertype')
  }

  async getUserInformation() {
    return await this._httpClient.get<DataResponse<User>>('/user')
  }

  async getUserSuggests() {
    return await this._httpClient.get<DataResponse<SuggestBooks>>('/user/suggests')
  }

  async getUserStatistics(option: StatsOption) {
    return await this._httpClient.get<DataResponse<StatsData>>('/user/stats', {
      params: option,
    })
  }

  async getUserSurvey() {
    console.log('✅ getUserSurvey')
    return await this._httpClient.get<DataResponse<LatestSurvey>>('/user/survey')
  }

  async postNickname(nickName: string) {
    console.log('✅ postNickname', nickName)
    return await this._httpClient.post<UpdateResponse>('/user/nickname', {
      nickName,
    })
  }

  async validateNickname(nickName: string) {
    console.log('✅ validateNickname', nickName)
    return await this._httpClient.post<UpdateResponse>('/u/nickname/valid', {
      nickName,
    })
  }

  async postConsentAllow(allow: boolean) {
    return await this._httpClient.post<UpdateResponse>('/user/consent', {
      allow,
    })
  }

  async postSurvey(survey: SurveyResult) {
    console.log('✅ postSurvey', survey)
    return await this._httpClient.post<UpdateResponse>('/user/survey', survey)
  }

  async allCollege() {
    return await this._httpClient.get<DataResponse<University[]>>('/list/uc')
  }

  async college(searchWord: string) {
    return await this._httpClient.post<DataResponse<University[]>>('/search/uc', {
      searchWord,
    })
  }

  async allHighschool() {
    return await this._httpClient.get<DataResponse<Highschool[]>>('/list/high')
  }

  async allMiddleschool() {
    return await this._httpClient.get<DataResponse<Middleschool[]>>('/list/middle')
  }

  async allCert() {
    return await this._httpClient.get<DataResponse<CertData[]>>('/list/cert')
  }

  async getMajor(code: string, searchWord: string) {
    return await this._httpClient.post<DataResponse<UniversityMajor[]>>('/search/uc/department', {
      code,
      searchWord,
    })
  }

  async getMiddleschoolClasses(code: string) {
    return await this._httpClient.post<DataResponse<MiddleschoolClass[]>>('/search/middle/class', {
      code,
    })
  }

  async getHighschoolClasses(code: string) {
    return await this._httpClient.post<DataResponse<HighschoolClass[]>>('/search/high/class', {
      code,
    })
  }

  async getUserDday() {
    return await this._httpClient.get<DataResponse<Dday>>('/user/dday')
  }

  async postUserDday(dday: Dday) {
    return await this._httpClient.post<UpdateResponse>('/user/dday', dday)
  }

  async putGoalDaily(timeLength: number, offset: number) {
    return await this._httpClient.put<UpdateResponse>('/user/goal/daily', { timeLength, offset })
  }

  async postGoalDaily(timeLength: number, offset: number) {
    return await this._httpClient.post<UpdateResponse>('/user/goal/daily', { timeLength, offset })
  }

  setAccessToken(accessToken: string) {
    this.#accessToken = accessToken
  }

  private decodeExp(acessToken: string | null): number {
    if (acessToken === null) return 0

    const payload = acessToken.substring(acessToken.indexOf('.') + 1, acessToken.lastIndexOf('.'))
    const decoded = window.atob(payload)
    const parsed = JSON.parse(decoded)

    return parsed.exp ? parsed.exp : 0
  }
}

import { useContext } from 'react'

import { type User } from '../../api/interfaces/sconnStudyData'
import { type CustomDate } from '../../util/CustomDate'

import { MyPageDispatchContext, MyPageStateContext } from './context'
import {
  MYPAGE_SET_IS_ONLINE,
  MYPAGE_SET_LAYOUT,
  MYPAGE_SET_STATS_COMPLETE,
  MYPAGE_SET_STATS_DATE_RANGE_LABEL,
  MYPAGE_SET_STATS_DISPLAY_CONTENTS,
  MYPAGE_SET_STATS_DURATION,
  MYPAGE_SET_STATS_END_DATE,
  MYPAGE_SET_STATS_IS_NO_CONTENTS,
  MYPAGE_SET_STATS_IS_TODAY,
  MYPAGE_SET_STATS_PERIOD,
  MYPAGE_SET_STATS_SELECTED_DATE,
  MYPAGE_SET_STATS_START_DATE,
  MYPAGE_SET_STATS_UPDATED_AT,
  MYPAGE_SET_SURVEY_PARTICIPATED,
  MYPAGE_SET_USER,
  type NativeLayoutType,
  type PeriodType,
} from './types'

function useMyPageState() {
  const state = useContext(MyPageStateContext)
  if (state === null) throw new Error('MyPageProvider not found.')
  return state
}

function useMyPageDispatch() {
  const dispatch = useContext(MyPageDispatchContext)
  if (dispatch === null) throw new Error('MyPageProvider not found.')
  return dispatch
}

export function useBookcafeData() {
  const { user } = useMyPageState()
  const dispatch = useMyPageDispatch()

  function setUser(user: User) {
    dispatch({ type: MYPAGE_SET_USER, payload: user })
  }

  function setSurveyParticipated(participated: boolean) {
    dispatch({ type: MYPAGE_SET_SURVEY_PARTICIPATED, payload: participated })
  }

  return { user, setUser, setSurveyParticipated }
}

export function useIsOnlineData() {
  const { isOnline } = useMyPageState()
  const dispatch = useMyPageDispatch()

  function setIsOnline(isOnline: boolean) {
    dispatch({ type: MYPAGE_SET_IS_ONLINE, payload: isOnline })
  }

  return { isOnline, setIsOnline }
}

export function useNativeLayout() {
  const { layout } = useMyPageState()
  const dispatch = useMyPageDispatch()

  function setNativeLayout(layout: NativeLayoutType) {
    dispatch({ type: MYPAGE_SET_LAYOUT, payload: layout })
  }

  return { layout, setNativeLayout }
}

export function useMyPage() {
  const { surveyParticipated, statsComplete } = useMyPageState()
  const dispatch = useMyPageDispatch()

  function setSurveyParticipated(participated: boolean) {
    dispatch({ type: MYPAGE_SET_SURVEY_PARTICIPATED, payload: participated })
  }

  function setStatsComplete(statsComplete: boolean) {
    dispatch({ type: MYPAGE_SET_STATS_COMPLETE, payload: statsComplete })
  }

  return {
    surveyParticipated,
    statsComplete,
    setSurveyParticipated,
    setStatsComplete,
  }
}

export function useStudyingReport() {
  const {
    statsPeriod,
    statsDateRangeLabel,
    statsUpdatedAt,
    statsSelectedDate,
    statsStartDate,
    statsEndDate,
    statsDuration,
    statsIsNoContents: noContents,
    statsIsToday: isToday,
    statsDisplayContents,
  } = useMyPageState()
  const dispatch = useMyPageDispatch()

  function setStatsPeriod(period: PeriodType) {
    dispatch({ type: MYPAGE_SET_STATS_PERIOD, payload: period })
  }

  function setStatsDateRangeLabel(label: string) {
    dispatch({ type: MYPAGE_SET_STATS_DATE_RANGE_LABEL, payload: label })
  }

  function setStatsUpdatedAt(timestamp: number | null) {
    if (timestamp === null) {
      dispatch({ type: MYPAGE_SET_STATS_UPDATED_AT, payload: '' })
    } else {
      const date = new Date(timestamp)
      const dateString = date.toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
      })
      dispatch({ type: MYPAGE_SET_STATS_UPDATED_AT, payload: dateString })
    }
  }

  function setStatsSelectedDate(date: CustomDate | null) {
    dispatch({ type: MYPAGE_SET_STATS_SELECTED_DATE, payload: date })
  }

  function setStatsDateRange(startDate: CustomDate | null, endDate: CustomDate | null) {
    dispatch({ type: MYPAGE_SET_STATS_START_DATE, payload: startDate })
    dispatch({ type: MYPAGE_SET_STATS_END_DATE, payload: endDate })
  }

  function setStatsDuration(duration: number) {
    dispatch({ type: MYPAGE_SET_STATS_DURATION, payload: duration })
  }

  function setStatsIsNoContents(isNoContents: boolean) {
    dispatch({ type: MYPAGE_SET_STATS_IS_NO_CONTENTS, payload: isNoContents })
  }

  function setStatsIsToday(isToday: boolean) {
    dispatch({ type: MYPAGE_SET_STATS_IS_TODAY, payload: isToday })
  }

  function setStatsDisplayContents(
    contents: Array<{ title: string; studyTime: string; thumbnail: string }>,
  ) {
    dispatch({ type: MYPAGE_SET_STATS_DISPLAY_CONTENTS, payload: contents })
  }

  return {
    statsPeriod,
    statsDateRangeLabel,
    statsUpdatedAt,
    statsSelectedDate,
    statsStartDate,
    statsEndDate,
    statsDuration,
    noContents,
    isToday,
    statsDisplayContents,
    setStatsPeriod,
    setStatsDateRangeLabel,
    setStatsUpdatedAt,
    setStatsSelectedDate,
    setStatsDateRange,
    setStatsDuration,
    setStatsIsNoContents,
    setStatsIsToday,
    setStatsDisplayContents,
  }
}

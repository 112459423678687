import { type Dispatch, type ReactNode, createContext, useReducer } from 'react'

import { myPageReducer } from './reducer'
import { type Action, type MyPageState } from './types'

type MyPageDispatch = Dispatch<Action>
export const MyPageStateContext = createContext<MyPageState | null>(null)
export const MyPageDispatchContext = createContext<MyPageDispatch | null>(null)

export const initialState: MyPageState = {
  isOnline: true,
  user: null,
  layout: 'regular',
  surveyParticipated: false,
  statsComplete: true,
  statsPeriod: 'monthly',
  statsDateRangeLabel: '',
  statsUpdatedAt: '',
  statsSelectedDate: null,
  statsStartDate: null,
  statsEndDate: null,
  statsDuration: 0,
  statsIsNoContents: true,
  statsIsToday: false,
  statsDisplayContents: [],
}

export function MyPageContextProvider({ children }: { children: ReactNode }) {
  const [mypage, dispatch] = useReducer(myPageReducer, initialState)

  return (
    <MyPageDispatchContext.Provider value={dispatch}>
      <MyPageStateContext.Provider value={mypage}>{children}</MyPageStateContext.Provider>
    </MyPageDispatchContext.Provider>
  )
}
